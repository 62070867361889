<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :xs="24">
          <el-row type="flex" justify="start">
            <h4>{{ $route.name }}</h4>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-descriptions size="large" border>
      <el-descriptions-item>
        <template #label> Mês de referência: </template>
        <el-date-picker
          v-model="dateReference"
          type="month"
          size="medium"
          format="MMMM"
        >
        </el-date-picker>
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions size="large" border>
      <el-descriptions-item>
        <template #label> Nota fiscal: </template>
        <el-row type="flex" justify="end">
          <el-radio-group
            v-model="searchWithInvoiceMode"
            size="large"
            @change="fetchOrders"
          >
            <el-radio-button label="Apenas com nota" />
            <el-radio-button label="Apenas sem nota" />
            <el-radio-button label="Indiferente" />
          </el-radio-group>
        </el-row>
      </el-descriptions-item>
    </el-descriptions>
    <el-row type="flex" :gutter="8">
      <el-col :md="12" :xs="24">
        <el-card shadow="always">
          <template #header>
            <h4 class="mb-0">Clientes</h4>
            <h6 class="text-muted">Maiores valores em compras no mês</h6>
          </template>
          <el-table
            :data="OrderBestClients"
            stripe
            style="width: 100%"
            :cell-style="() => 'text-align:center;'"
          >
            <el-table-column prop="name" label="nome" min-width="250">
            </el-table-column>
            <el-table-column
              label="valor"
              prop="amount"
              :formatter="({ amount }) => currencyFormatter.format(amount)"
            >
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
      <el-col :md="12" :xs="24">
        <el-card shadow="always">
          <template #header>
            <h4 class="mb-0">Vendas abaixo da tabela</h4>
            <h6 class="text-muted">
              Total de produtos vendidos abaixo da tabela
            </h6>
          </template>
          <el-table
            :data="OrderClientsWorstSells"
            stripe
            style="width: 100%"
            :cell-style="() => 'text-align:center;'"
          >
            <el-table-column prop="name" label="nome" min-width="250">
            </el-table-column>
            <el-table-column label="qtd" prop="total"> </el-table-column>
          </el-table>
        </el-card>
      </el-col>
      <el-col :md="12" :xs="24"> </el-col>
    </el-row>
    <el-row type="flex" :gutter="8">
      <el-col :md="12" :xs="24">
        <el-card shadow="always">
          <template #header>
            <h4 class="mb-0">Produtos</h4>
            <h6 class="text-muted">Peso total vendido</h6>
          </template>
          <el-table
            :data="ProductsTotalSells"
            stripe
            style="width: 100%"
            :cell-style="() => 'text-align:center;'"
          >
            <el-table-column prop="name" label="nome" min-width="250">
            </el-table-column>
            <el-table-column
              label="qtd"
              prop="total_weight"
              :formatter="
                ({ total_weight }) =>
                  weightFormatter.format(Number(total_weight)) + ' kg.'
              "
            >
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
      <el-col :md="12" :xs="24">
        <el-card shadow="always">
          <template #header>
            <h4 class="mb-0">Vendas abaixo da tabela</h4>
            <h6 class="text-muted">
              Produtos com maior incidência de vendas com preço abaixo da tabela
            </h6>
          </template>
          <el-table
            :data="ProductsWorstSells"
            stripe
            style="width: 100%"
            :cell-style="() => 'text-align:center;'"
          >
            <el-table-column prop="name" label="nome" min-width="250">
            </el-table-column>
            <el-table-column label="qtd" prop="total"> </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <el-row type="flex" :gutter="8">
      <el-col :md="12" :xs="24">
        <el-card shadow="always">
          <template #header>
            <h4 class="mb-0">Vendedores</h4>
            <h6 class="text-muted">
              Total de vendas para cada vendedor no mês
            </h6>
          </template>
          <el-table
            :data="SellerTotalSells"
            stripe
            style="width: 100%"
            :cell-style="() => 'text-align:center;'"
          >
            <el-table-column prop="name" label="nome" min-width="250">
            </el-table-column>
            <el-table-column
              label="valor"
              prop="amount"
              :formatter="
                ({ total_amount }) => currencyFormatter.format(total_amount)
              "
            >
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
      <el-col :md="12" :xs="24">
        <el-card shadow="always">
          <template #header>
            <h4 class="mb-0">Vendas abaixo da tabela</h4>
            <h6 class="text-muted">
              Quantidade de produtos abaixo da tabela para o mês
            </h6>
          </template>
          <el-table
            :data="SellerWorstSells"
            stripe
            style="width: 100%"
            :cell-style="() => 'text-align:center;'"
          >
            <el-table-column prop="name" label="nome" min-width="250">
            </el-table-column>
            <el-table-column label="valor" prop="total"> </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import OrderService from "../services/orders";
import { weightFormatter, currencyFormatter } from "../utils/formatters";
export default {
  name: "OrderTOP10Report",
  data: () => ({
    isLoading: false,
    weightFormatter,
    currencyFormatter,
    dateReference: new Date(),
    categories: null,
    orders: null,
    packings: null,
    searchWithInvoiceMode: "Apenas com nota",
    selectedProductCategory: null,
  }),
  computed: {
    Orders() {
      return this.orders || [];
    },
    OrderBestClients() {
      const clientOrders = this.groupOrdersByClients();

      const totalBoughtClients = {};

      for (let c in clientOrders)
        totalBoughtClients[c] = clientOrders[c].reduce(
          (total, { total_amount }) => total + Number(total_amount),
          0
        );

      const compiledTotalBoughtClients = Object.entries(totalBoughtClients).map(
        (a) => ({ name: a[0], amount: a[1] })
      );

      return compiledTotalBoughtClients
        .sort(({ amount: a }, { amount: b }) => (a < b ? 1 : -1))
        .filter((a, i) => i < 10);
    },
    OrderClientsWorstSells() {
      const clientOrders = this.groupOrdersByClients();

      const totalBoughtClients = {};

      for (let c in clientOrders)
        totalBoughtClients[c] = clientOrders[c]
          .map(({ products }) => products)
          .flat()
          .filter(
            (sell) =>
              Number(sell.mounted.price?.female_std) > Number(sell.amount)
          ).length;

      const compiledTotalBoughtClients = Object.entries(totalBoughtClients).map(
        (a) => ({ name: a[0], total: a[1] })
      );

      return compiledTotalBoughtClients
        .sort(({ total: a }, { total: b }) => (a < b ? 1 : -1))
        .filter((a, i) => i < 10);
    },
    ProductsTotalSells() {
      const sellsProducts = this.groupSellsByProducts();

      const totalSoldProducts = {};

      for (let c in sellsProducts)
        totalSoldProducts[c] = sellsProducts[c].reduce(
          (total, { weight }) => total + Number(weight),
          0
        );

      const compiledTotalWeightSoldByProduc = Object.entries(
        totalSoldProducts
      ).map((a) => ({ name: a[0], total_weight: a[1] }));

      return compiledTotalWeightSoldByProduc
        .sort(({ total_weight: a }, { total_weight: b }) => (a < b ? 1 : -1))
        .filter((a, i) => i < 10);
    },
    ProductsWorstSells() {
      const sellsProducts = this.groupSellsByProducts();

      const totalSoldProducts = {};

      for (let c in sellsProducts)
        totalSoldProducts[c] = sellsProducts[c].filter(
          (sell) => Number(sell.mounted.price?.female_std) > Number(sell.amount)
        ).length;

      const compiledTotalWeightSoldByProduct = Object.entries(
        totalSoldProducts
      ).map((a) => ({ name: a[0], total: a[1] }));

      return compiledTotalWeightSoldByProduct
        .sort(({ total: a }, { total: b }) => (a < b ? 1 : -1))
        .filter((a, i) => i < 10);
    },
    SellerTotalSells() {
      const sellerOrders = this.groupOrdersBySellers();

      const totalSoldProducts = {};

      for (let c in sellerOrders)
        totalSoldProducts[c] = sellerOrders[c].reduce(
          (total, { total_amount }) => total + Number(total_amount),
          0
        );

      const compiledTotalWeightSoldByProduct = Object.entries(
        totalSoldProducts
      ).map((a) => ({ name: a[0], total_amount: a[1] }));

      return compiledTotalWeightSoldByProduct
        .sort(({ total_amount: a }, { total_amount: b }) => (a < b ? 1 : -1))
        .filter((a, i) => i < 10);
    },
    SellerWorstSells() {
      const sellerOrders = this.groupOrdersBySellers();

      const totalSold = {};

      for (let c in sellerOrders)
        totalSold[c] = sellerOrders[c]
          .map(({ products }) => products)
          .flat()
          .filter(
            (sell) =>
              Number(sell.mounted.price?.female_std) > Number(sell.amount)
          ).length;

      const compiledTotalSold = Object.entries(totalSold).map((a) => ({
        name: a[0],
        total: a[1],
      }));

      return compiledTotalSold
        .sort(({ total: a }, { total: b }) => (a < b ? 1 : -1))
        .filter((a, i) => i < 10);
    },
    StartOfMonth() {
      const startOfMonth = new Date(this.dateReference);
      startOfMonth.setDate(1);
      startOfMonth.setHours(0, 0, 0, 0);

      return startOfMonth;
    },
    EndOfMonth() {
      const endOfMonth = new Date(this.dateReference);
      endOfMonth.setMonth(endOfMonth.getMonth() + 1);
      endOfMonth.setDate(0);
      endOfMonth.setHours(23, 59, 59, 999);

      return endOfMonth;
    },
  },
  watch: {
    dateReference() {
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.fetchOrders();
    },
    groupOrdersBySellers() {
      return this.orders?.reduce((group, order) => {
        const name = order.seller?.name;

        if (name in group) group[name].push(order);
        else group[name] = [order];
        return group;
      }, {});
    },
    groupOrdersByClients() {
      return this.orders?.reduce((group, order) => {
        const name = order.client?.legal?.social_name || order.client?.name;

        if (name in group) group[name].push(order);
        else group[name] = [order];
        return group;
      }, {});
    },
    groupSellsByProducts() {
      return this.orders
        ?.map(({ products }) => products)
        ?.flat()
        ?.reduce((group, sell) => {
          const name = sell.product?.name;

          if (name in group) group[name].push(sell);
          else group[name] = [sell];
          return group;
        }, {});
    },
    shouldFetchWithInvoices() {
      return this.searchWithInvoiceMode === "Apenas com nota";
    },
    explodeAnimalsInPackings() {
      return this.packings?.map((p) => p.animals).flat();
    },
    explodeProductsInOrders() {
      return this.orders?.map((o) => o.products).flat();
    },
    sumProductsByProductCategoryId(categoryId, products) {
      return products?.reduce(
        (t, p) =>
          t + (p.product.category.uid === categoryId ? Number(p.weight) : 0),
        0
      );
    },
    sumBoughtPackingsWeight() {
      return this.explodeAnimalsInPackings().reduce(
        (total, { weight }) => total + Number(weight),
        0
      );
    },
    sumBoughtValueForCattleCategory() {
      const category = this.categories.find((c) => c.name === "BOVINOS");

      category.boughtQuantityPackings = this.sumBoughtPackingsWeight();
    },
    sumWeightByProductCategory() {
      this.categories = this.categories?.map((category) => {
        return {
          ...category,
          soldQuantity: this.sumProductsByProductCategoryId(
            category.uid,
            this.explodeProductsInOrders()
          ),
          boughtQuantityEntries: this.sumProductsByProductCategoryId(
            category.uid,
            this.entries || []
          ),
        };
      });
    },
    async fetchOrders() {
      this.isLoading = true;

      const filters = {
        include_product_category: true,
        include_product_price: true,
        delivered_at_start: this.StartOfMonth,
        delivered_at_end: this.EndOfMonth,
        status: "finalized,completed,paid,delivered",
      };

      if (this.searchWithInvoiceMode !== "Indiferente")
        filters.has_invoice = this.shouldFetchWithInvoices();

      const { orders } = await OrderService().index(filters);

      if (orders) {
        this.orders = orders;
      } else this.orders = null;

      this.isLoading = false;
    },
  },
};
</script>
<style>
.el-table .success {
  background-color: #dcedc8 !important;
}
</style>
